import React from 'react';
import { ListGroup as ListGroupBootstrap, useClassNameMapper } from 'react-bootstrap';
import { TransitionGroup } from 'react-transition-group';
import type { ListGroupProps, ListItem, ListProps } from '..';
import Transition, { TransitionVariant } from '../../Transition/Transition';

/**
 * The `list` variant for `List`. Displays a list of items
 * in a Bootstrap List Group view.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const ListGroup = <T extends ListItem>({
  items = [],
  className,
  children,
  flush = true,
  listItemClassName = (): null => null
}: ListGroupProps<T> & ListProps<T>): React.ReactElement => {
  const cx = useClassNameMapper();
  return (
    <ListGroupBootstrap variant={flush ? 'flush' : null} as="ul" className={cx(className)}>
      <TransitionGroup component={null}>
        {items.map((item, i) => (
          <Transition key={item?.id ?? `item:${i}`} variant={TransitionVariant.FADE}>
            <ListGroupBootstrap.Item as="li" className={cx(listItemClassName(item))}>
              {children(item)}
            </ListGroupBootstrap.Item>
          </Transition>
        ))}
      </TransitionGroup>
    </ListGroupBootstrap>
  );
};

export default ListGroup;
